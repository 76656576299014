// dropzone
ngx-dropzone {
  border: 2px dashed var(--theme-deafult) !important;
  height: auto !important;
  background: rgba(115, 102, 255, 0.1) !important;
  padding: 50px;
  i {
    font-size: 48px !important;
    color: var(--theme-default);
  }
}
.dropzone.dz-clickable * {
  background: transparent;
}
ngx-dropzone-label {
  z-index: 6 !important;
}
.dz-message {
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: $dark;
  }
  span {
    color: $dark;
    font-size: 14px;
  }
}
.dz-message {
  h4 {
    font-weight: 200;
    margin-top: 20px;
  }
}

.node-content-wrapper.node-content-wrapper-active {
  &:hover {
    background-color: $primary-color;
    color: $white;
  }
}
.node-content-wrapper-active.node-content-wrapper-focused {
  background-color: $primary-color;
  color: $white;
}

.custom-datepicker {
  border: 1px solid $transparent-color !important;
  .form-select {
    color: #000;
  }
  .custom-day {
    font-size: 14px;
    text-align: center;
    padding: 0;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
  }
  ngb-datepicker,
  .dropdown-menu {
    border: none;
    border-radius: 8px;
    width: 100%;
  }
  .ngb-dp-header {
    padding: 20px 0 10px 0;
  }
  .ngb-dp-months {
    display: inherit;
    width: 100%;
    background-color: $white;
  }
  .ngb-dp-weekday {
    color: $theme-body-font-color;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11.2px;
  }
  .ngb-dp-weekdays {
    border-bottom: 1px solid $transparent-color;
  }
  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday,
  .ngb-dp-arrow {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-style: inherit;
    [ngbDatepickerDayView] {
      width: 100%;
      height: 30px;
      line-height: 30px;
    }
    .btn-light {
      background-color: $white !important;
      border-color: $white !important;
      font-size: 14px;
      color: $black !important;
      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: $primary-color !important;
        color: $white !important;
      }
    }
    .btn-link {
      color: $white;
      .ngb-dp-navigation-chevron {
        width: 9px;
        height: 9px;
      }
    }
  }
  .ngb-dp-navigation-select {
    .form-select {
      width: 100px;
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  .bg-light {
    background-color: $white !important;
  }
  .ngb-dp-month {
    &:first-child {
      .ngb-dp-week {
        padding-left: 15px;
      }
    }
    &:last-child {
      .ngb-dp-week {
        padding-right: 15px;
      }
    }
    .ngb-dp-week {
      &:last-child {
        padding-bottom: 15px;
      }
    }
  }
}

.swiper {
  .swiper-wrapper {
    height: 35px;
  }
}

.modal-dialog {
  .modal-content {
    .modal-body {
      .btn-close {
        position: absolute !important;
        right: 15px !important;
        top: 10px !important;
      }
    }
  }
}

.product-modal {
  .product-box {
    .product-details {
      a {
        :focus-visible {
          color: transparent;
        }
      }
      h6 {
        text-transform: capitalize;
      }

      .product-price {
        font-size: 22px;
        margin-bottom: 10px;
      }

      .product-view {
        padding: 20px 0;
        border-top: 1px dotted $semi-dark;
        border-bottom: 1px dotted $semi-dark;
      }

      .product-size {
        margin: 20px 0;

        ul {
          li {
            display: inline-block;
            + li {
              margin-left: 5px;
            }
          }
        }
      }

      .addcart-btn {
        margin-top: 30px;
      }

      .product-qnty {
        margin-bottom: 20px;

        .input-group {
          width: 35%;

          .btn {
            padding: 5px 12px;
          }

          .btn-primary {
            background-color: $semi-dark !important;
            border: 1px solid $semi-dark !important;
          }
        }
      }
    }
  }
}

.table-responsive {
  .form-select {
    color: $theme-body-font-color;
  }
}
.action {
  li {
    list-style: none;
  }
}

.accordion-button:not(.collapsed) {
  color: $primary-color;
}

ngb-datepicker {
  ngb-datepicker-navigation-select {
    select {
      background-color: transparent !important;
    }
  }
  .ngb-dp-header {
    background-color: transparent;
    padding-top: 15px;
    .ngb-dp-arrow-btn {
      background-color: $primary-color;
      color: $white;
      padding: 0px 10px;
    }
  }
  .ngb-dp-weekday {
    color: $primary-color;
  }
  .ngb-dp-content {
    .ngb-dp-week {
      color: $dark-small-font-color;
      .btn-light {
        background-color: transparent !important;
        border-color: $dark-card-border !important;
        color: rgba(82, 82, 108, 0.8) !important;
        &:hover {
          background-color: $primary-color !important;
          border-color: $primary-color !important;
          color: $white !important;
          &.text-muted {
            color: $white !important;
          }
        }
        &.text-white {
          background-color: $primary-color !important;
          border: 1px solid $primary-color !important;
          color: $white !important;
        }
      }
    }
  }
  .ngb-dp-weekdays {
    background-color: transparent;
  }
}
.ngb-dp-month-name {
  background-color: $dark-card-background;
  color: $dark-all-font-color;
}

.digits {
  .input-group {
    .btn {
      z-index: 0 !important;
    }
  }
}


/* common css for TIBSS */ 


.registration-wrapper {
  border: 1px solid #f6f6f6;
  margin-top: 50px;
}

@media screen and (min-width: 769px){
  .mobile-layout-top-85 {
    margin-top: 50px
  }
  .registration-wrapper {
      margin-top: 150px;
  }
}

.card-icon {
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  .fa {
      font-size: 50px;
      color: green;
  }
  
}

.cursor-pointer {
  cursor: pointer;
}
.card {
  .badge-position-right {
    right: 15px;
  }
}

.border-top-left-radius {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.network-icon-wrap {
  svg {
    height: 100px;
    width: 100px;
  }
}

.price-badge {
  right: 15px;
}
.program-form {
.width-50 {
  width: 60%;
  select {
    width: 90%;
  }
  
}

.close-modal-nsl, .close-modal {
  font-size: 25px;

  &:is(.p-absolute){
     right: 10px; 
  }
}


.mega-title-badge {
  cursor: pointer;
}

.check-card {
  top: 20px;
  left: calc(50% - 20px);
  cursor: pointer;
  transition: all .51s ease-in-out;  
  svg {
    width: 40px;
    height: 40px;
    transition: all .51s ease-in-out;  
  }
  opacity: 0.25;
}

.b-success {
  .check-card {
    svg {
      color: #54BA4A;
    }
    opacity: 1;
  }
}

.b-warning {
  .check-card {
    svg {
      color: #FFAA05;
    }
    opacity: 0.7;
  }
}

.card {
  &.opacity-8 {
    opacity: 0.8;
  }

&.min-h-120 {
  min-height: 120px;
}
}
.edit-icon {
position: absolute;
right: 2px;
bottom: -6px;
font-size: 29px;
z-index: 2;
cursor: pointer;
}

.badge-list {
left: 10px;
bottom: 5px;
}
.close-modal {
right: 10px;
top: 10px;
font-size: 30px;
cursor: pointer;
}
}

.cursor-pointer {
cursor: pointer;
}

.custom-form-group {
display: flex;
align-items: center;
}

.inline-input {
width: 30px;
height: 24px;
display: inline-block;
text-align: center;
margin-left: 10px;
}

.login-main {
  &.program-form, &.plan-form, &.device-from {
    min-height: 95vh;
    &.program-review {
      min-height: auto;
    }
  }
  &.loader-carousel {
    min-height: 95vh;
    min-width: 700px;
  }
}

.ib-user-response {
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 20px;
  font-size: 14px;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  div {
    color: #ffffff;
    
  }
}

.close-modal {
  font-size: 25px;

  &:is(.p-absolute){
     right: 10px; 
  }
}


// responsive css

@media (max-width: 768px) {
  .bs-stepper-label {
    display: none;
  }  
}

.api-process-loader {
  z-index: 10;
  position: fixed;
  left: 0;
  height: 100%;
  background: rgba(255,255,255,0.3);
  width: 100%;
  top: 0;
  .loader-box {
    height: 100%;
  }
}

.width-50 {
  width: 50%;
  min-width: 160px;
}


.formatted-data {
  position: absolute;
  top: 44px;
  left: 10px;
  color: #898989;
  background-color: #f3f3ff;
  font-size: 14px;
}

#flexCheckACP {
  display: none;
}
 
@media screen and (min-width: 768px) {
  .bs-stepper-header {
    position: fixed;
    top: 0px;
    left: calc(50% - 350px);
    max-width: 700px;
    background-color: white;
    z-index: 100;
    padding-bottom: 48px;
    padding-left: 52px;
    padding-right: 55px;
    box-shadow: 0px 2px 2px 2px rgba(210,210,210, 0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  #test-l-1, #test-l-2, #test-l-3, #test-l-4 {
    margin-top: 60px;
  }

  .min-height140 {
    min-height: 140px;
  }

  // .gte-md-m-t-50 {
  //   margin-top: 50px !important;
  // }
  // .gte-md-m-t-40 {
  //   margin-top: 40px !important;
  // }
  // .gte-md-m-t-30 {
  //   margin-top: 30px !important;
  // }
  // .gte-md-m-t-20 {
  //   margin-top: 20px !important;
  // }
}

.img-40 {
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .enroll-label {
    position: fixed;
    z-index: 1000;
    top: 85px;
    left: 0;
    right: 0;
    p {
      font-weight: 600;
    }
   }

  .container-fluid{
    &.login-card {
      align-items: flex-start;
      padding-top: 50px;
     }
  }
}

@media screen and (max-width: 767px) {
  .mobile-layout-top-85 {
    margin-top: 85px
  }
  .bs-stepper-header {
    margin: 0 -10px;
    text-align: center;
    position: fixed;
    z-index: 14;
    top: -5px;
    left: 10px;
    right: 10px;
    background: white;
    padding-bottom: 42px;
    box-shadow: 0px 4px 9px -3px rgba(0,0,0,.9);
  }
  .enroll-label {
    position: fixed;
    z-index: 100;
    top: 50px;
    left: 0;
    right: 0;
  }


  
  .img-h200 {
    height: 200px;
  }
  .container-fluid{
    &.login-card {
      align-items: flex-start;
      padding-top: 0px;
     }
  }
}

.order-box .qty {
  margin-bottom: 10px !important;
}

.register-form {
  input.is-invalid {
    border: 1px solid #ff0000 !important;
  }
}


.success-icon {
  opacity: 0.5;
  app-feather-icons {
    svg {
        width: 80px !important;
        height: 80px !important;
        color: green;
    }
  }
}

.warning-icon {
  app-feather-icons {
    svg {
        width: 80px !important;
        height: 80px !important;
    }
  }
}


.bs-stepper .step-trigger.disabled, .bs-stepper .step-trigger:disabled {
  opacity: 1 !important;
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items .ng-option {
    padding: 8px 10px;
    border-bottom: 0.6px solid #d6d6d6;
    &:hover:not(.ng-option-selected) {
      color: #333333;
      transition: all 0.35 ease-in-out;
    }
    &.ng-option-selected {
      color: #333333;
      &::after {
        content: "\f058";
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        position: absolute;
        right: 10px;
        margin-top: 4px;
        color: green;
      }
    }
    &:last-child {
      border-bottom-color: transparent;
    }
  }
  .scroll-host {
    background-color: #f3f3ff;
    left: -11px;
    top: 9px;
  }
}

.custom-inline-loader {
  margin-top: -40px;
  right: 7px;
  background-color: #f3f3f3;
}
.address-img {
  width: 100px;
  margin-top: 10px;
}
.step-trigger {
  padding-bottom: 10px !important;
}

.acp-consent-wrapper {
  input[type='checkbox']{
    display: none;
  }
}
.beware-icon {
  position: absolute;
  left: calc(50% - 51px);
  margin-top: -3px;
}

.esign-check {
  input[type=checkbox] {
    display: none !important;
  }
}
.opacity-2 {
  opacity: 0.2;
}

.tool-tip-wrapper {
  .tooltip .tooltip-inner {
    width: 320px !important;
  }
}
.tribal-tooltip-icon {
  margin-left: 10px;
  z-index: 1;
}

.accordian-image-inline {
  left: 12px;
  height: 30px;
}
.program-image-wrapper {
  img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}
.program-form {
  .media {
    position: relative;
    justify-content: space-around !important;
  }
}
 
.device-not-eligible-icon {
  position: absolute;
  z-index: 1;
  top: calc(30% - 25px);
  left: calc(50% - 25px);
  svg {
    color: red;
    width: 50px;
    height: 50px;

  }
}

.card-disable {
  opacity: 0.5;
}